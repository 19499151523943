import firebase from "firebase";
import { CoreDataType, IndexedString, ValueAtom } from "./core-data-types";
import { MultiValueBox, SingleValueBox } from "./fundamental";
import { ScreeningType } from "../constants/screenings";
import { LanguageISO } from "../constants/locales";
import { NumberAndUnitType, SystemOfMeasure } from "./measurements";
import {
  BASE_32_NUM_BIASED,
  escapeForCSV,
  isValidDate,
  randomId,
} from "../utils";
import { nanoid } from "nanoid";
import moment from "moment";
import { QuestionDefinition } from "./questions";
import { ResponseLayout } from "./layouts";

export const EMPTY_ANSWER = {};
Object.preventExtensions(EMPTY_ANSWER);

export interface AbstractAnswer {
  key?: string;
  isMulti: boolean;
  isComputed?: boolean;
  // isNonconforming?: boolean;
  nonconformingValues?: NonconformingWrappedValue[];
  questionKey?: string;

  // type-specific properties that we haven't bothered codifying somewhere
  system?: SystemOfMeasure;
}

export interface SingleValuedAnswer<A extends ValueAtom>
  extends AbstractAnswer,
    SingleValueBox<A> {
  isMulti: false;
  value: A;
  values?: never;
}

export interface MultiValuedAnswer<A extends ValueAtom>
  extends AbstractAnswer,
    MultiValueBox<A> {
  isMulti: true;
  values: A[];
  value?: never;
}

/**
 * The reason a value doesn't conform with the question's assumptions, which may
 * range from the technical (datatype mismatch) to pragmatic (the user didn't
 * understand or refused to answer).
 */
export enum NonconformingValueKind {
  /**
   * A value that the system doesn't understand and doesn't match the spec.
   * Since we don't know why it's here, we preserve it but can't do much else.
   *
   * This value should only be a last resort to other explanations.
   */
  Noncompliant = "noncompliant_general",
  /**
   * A subtype of Noncompliant used to mark when we know a value is "historical"
   * in the sense that it matches a spec different from the one the answer set
   * is loaded with.
   */
  Historical = "noncomplicant_historical",
  /**
   * A user is choosing not to answer. NOTE: We haven't necessarily settled on
   * whether this value is populated for *every* skipped question; for now one
   * should assume it's only when the user is in a position where they must
   * interact with an answer-like UI element in order to skip and not simply
   * from clicking "next" on a question that didn't enforce completion.
   *
   * If possible, the UI should be capable of collecting one or more of the
   * alternatives below when they are relevant.
   */
  Skip = "skip_generic",
  /**
   * The question is irrelevant such that its choices, data type, phrasing, or
   * entire premise/context is not accurate to the user.
   */
  NotApplicable = "not_applicable",
  /**
   * The user doesn't know the answer, doesn't know which of multiple options
   * to select, or otherwise can't accurately use the input to provide a value
   * they are confident in.
   */
  Unsure = "unsure",
  /**
   * The user knows the answer but is not able to enter it, due to limitations
   * in the interface. This could be as simple as a choice missing from a list,
   * or could be inconsistencies such as a numeric input applying a constrained
   * range when the user needs a value outside of it.
   *
   * When this type of behavior is *expected*, the app may provide a free-form
   * input fallback via the UserDefined option below.
   */
  NotAvailable = "not_available",
  /**
   * The answer represents a ComputedQuestion that has run, but for whom an
   * empty response has been provided. In some cases this is just noise, but can
   * be important to clear values that had previously been calculated but
   * became inapplicable or incomputable again.
   */
  InactiveComputation = "computation_inactive",
  /**
   * The user left the interface in an incomplete state. This should only be
   * used when *some* data is collected but it cannot conform to the model. For
   * example, when a date is partially typed but could not be parsed, or when
   * an anatomical model has been navigated but without a final selection.
   */
  IncompleteResponse = "incomplete_response",
  /**
   * The user has substituted their own free-form response. This is often a
   * "nonconforming" value because it is quite literally not one of the options
   * provided (as in a choice-like question) or defies the overall type (as in
   * a text explanation for an exception to a numeric or date entry).
   */
  UserDefined = "other_user_defined",
}

export interface NonconformingWrappedValue {
  kind: NonconformingValueKind;
  value: any;
  // originalContext?: any,
  // index?: number
}

export interface NonconformingAnswer {
  // isNonconforming: true,
  nonconformingValues: NonconformingWrappedValue[];
}

export type Answer<V extends ValueAtom> =
  | SingleValuedAnswer<V>
  | MultiValuedAnswer<V>;
export type GeneralAnswer =
  | SingleValuedAnswer<ValueAtom>
  | MultiValuedAnswer<ValueAtom>;

// TODO!
export type DerivedAnswerHolder = { special: string };

export function onlyAcceptMulti(
  a: GeneralAnswer | undefined | null
): MultiValuedAnswer<ValueAtom> | undefined {
  if (!!a) {
    if (a.isMulti) {
      return a as MultiValuedAnswer<ValueAtom>;
    }
  }
  return undefined;
}
export function onlyAcceptMultiChoice(
  a: GeneralAnswer | undefined | null
): MultiValuedAnswer<IndexedString> | undefined {
  if (!!a) {
    if (
      a.isMulti &&
      a.values.every((v) =>
        Number.isSafeInteger((v as IndexedString).choiceIndex)
      )
    ) {
      return a as MultiValuedAnswer<IndexedString>;
    }
  }
  return undefined;
}
export function onlyAcceptSingleChoice(
  a: GeneralAnswer | undefined | null
): SingleValuedAnswer<IndexedString> | undefined {
  if (!!a) {
    if (
      !a.isMulti &&
      (!a.value || Number.isSafeInteger((a.value as IndexedString).choiceIndex))
    ) {
      return a as SingleValuedAnswer<IndexedString>;
    }
  }
  return undefined;
}

export enum SpecialAnswerKeys {
  GeneratedReportID = "_reportID",
  ConsentTimestamp = "_consentTime",
}
export enum DerivedAnswerKey {
  DisambiguatedChiefComplaint = "chief complaint",
  DetailedChiefComplaint = "detailed chief complaint",
  DisambiguatedRegionOfProblem = "region of problem",
}

export enum HumanReadableIDAlgorithm {
  Base32 = "base32-random",
  NanoID = "nanoid-random",
}
export const DEFAULT_HRI_ALGORITHM = HumanReadableIDAlgorithm.Base32;
export interface HumanReadableID {
  algo: HumanReadableIDAlgorithm;
  id: string;
  bytes?: firebase.firestore.Blob;
}

export function generateHumanReadableId(
  alg: HumanReadableIDAlgorithm,
  length: number = 4
): string {
  switch (alg) {
    case HumanReadableIDAlgorithm.Base32:
      return randomId(length, BASE_32_NUM_BIASED);
    case HumanReadableIDAlgorithm.NanoID:
      return nanoid(length);
    default:
      throw Error(`Unknown algorithm ${alg}`);
  }
}

export function createBaseAnswerEntities(): Record<string, GeneralAnswer> {
  return {
    [SpecialAnswerKeys.GeneratedReportID]: {
      key: SpecialAnswerKeys.GeneratedReportID,
      isMulti: false,
      value: { value: generateHumanReadableId(DEFAULT_HRI_ALGORITHM) },
    },
  };
}

/**
 * What type of event caused the last save of this answer set
 */
export enum AnswerSetSubmissionStatus {
  FlowCompleted = "completed patient flow",
  WaypointReached = "patient flow waypoint",
  QuitDialog = "quit - dialog",
  QuitTabClosure = "quit - tabended",
  QuitTimeout = "quit - timeout",
  Unknown = "unknown",
}

export function naturalSortForSubmissionStatus(
  status: AnswerSetSubmissionStatus
): number {
  // This is a silly transform function, but the point here is to emphasize that
  // the natural alphabetical order of the submission statuses above is also a
  // logical sort order for them to be arranged in to the patient.
  return [
    AnswerSetSubmissionStatus.FlowCompleted,
    AnswerSetSubmissionStatus.WaypointReached,
    AnswerSetSubmissionStatus.QuitDialog,
    AnswerSetSubmissionStatus.QuitTimeout,
    AnswerSetSubmissionStatus.QuitTabClosure,
  ].indexOf(status);
}

/**
 * The maximum amount of time a record is allowed to be "resumed" for.
 */
export const MAX_RESUME_TIME_MS = 2 * 60 * 60 * 1000; // 2 hr

// Temporary value that makes all entries resumable, for testing only
// export const MAX_RESUME_TIME_MS = Date.UTC(2030) - Date.now();

export interface AnswerSet {
  /**
   * Timestamp of when this set was last submitted.
   */
  submitted: number;

  /**
   * What was the context/action that submitted this answer set.
   */
  submissionType: AnswerSetSubmissionStatus;

  /**
   * The IDs of all sessions that contributed to this answer set.
   */
  sessionIds: string[];

  /**
   * The ID of the user that this set is associated with.
   */
  userId: string;

  /**
   * The organization this answer set was collected on behalf of. Right now,
   * every answer set is exculusively contained within a single organization.
   * (This greatly simplifies the siloing logic and function of dashboards.)
   */
  organizationId: string;

  /**
   * Name of the questionnaire this answer set was generated from.
   */
  questionnaire: ScreeningType | "unknown";

  /**
   * The language the questionnaire was completed in.
   */
  language: LanguageISO;

  /**
   * The firebase ID of the answer set itself.
   */
  id?: string;

  /**
   * An optional identifier designed to be used by humans to locate the record.
   */
  humanReadableId?: HumanReadableID;

  pageAtSave?: number;

  /**
   * The current answers as a map object from the answer keys to their values.
   */
  answers: {
    [key: string]: Answer<ValueAtom>;
  };
}

/**
 * Identify if an Answer object has a value provided or if it is "unanswered".
 * @param acceptNonconforming Whether to count an answer with solely
 *        nonconforming values as answered (defaults to yes).
 */
export function isUnanswered(
  a: GeneralAnswer | null | undefined,
  acceptNonconforming: boolean = true
) {
  if (!a) return true;
  if (a.isMulti) {
    if (!Array.isArray(a.values)) {
      // this would actually be a malformed answer
      console.error(`isMulti answer had no values array`, a);
      return true;
    }
    if (a.values.length > 0) {
      return false;
    }
  } else {
    if (!("value" in a) || !("value" in a.value)) {
      return true;
    }
    if (a.value.value !== null) {
      return false;
    }
  }
  return !acceptNonconforming || (a?.nonconformingValues?.length ?? -1) <= 0;
}

// TODO: Once available, include display logic for the report layout (to help group and order report for analysis)
export function stringifyForCSV(
  key: string,
  answer: GeneralAnswer,
  reportLabel: string,
  question?: QuestionDefinition
): Array<[string, string, string]> {
  const out: Array<[string, string, string]> = [];

  // the two special cases here are dates and measurements

  let index = 1;
  const isDate = question?.coreType === "calendar date";
  const isMeasure =
    question?.coreType === "measurement" ||
    question?.layout === ResponseLayout.PseudoMeasurement;

  // Ensure reportLabel is properly escaped
  const safeReportLabel = `"${reportLabel.replace(/"/g, '""')}"`;

  if (answer.isMulti) {
    answer.values?.forEach((va) => {
      const outValue = isDate
        ? moment(va.value).format("yyyy-MM-DD HH:mm:ss")
        : isMeasure
        ? escapeForCSV(`${va.value} ${(va as NumberAndUnitType).unit}`)
        : escapeForCSV(va.value);
      out.push([escapeForCSV(`${key}#${index}`), safeReportLabel, outValue]);
      index++;
    });
    answer.nonconformingValues?.forEach((ncv) => {
      // for now NCVs can't be dates or measurements
      out.push([
        escapeForCSV(`${key}#${index}`),
        safeReportLabel,
        escapeForCSV(ncv.value),
      ]);
      index++;
    });
  } else {
    if (answer.nonconformingValues?.length === 1) {
      out.push([
        escapeForCSV(key),
        safeReportLabel,
        escapeForCSV(answer.nonconformingValues[0].value),
      ]);
    } else {
      // the "typical" singleton case is here:
      const outValue =
        isDate && answer.value
          ? moment(answer.value.value).format("yyyy-MM-DD HH:mm:ss")
          : escapeForCSV(answer.value?.value);
      out.push([escapeForCSV(key), safeReportLabel, outValue]);
    }
  }
  return out;
}
