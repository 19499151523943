import { capitalizeFirstLetter } from "..";
import { NO_ANSWER_SIGNAL } from "../../components/Questionnaire/Responses/AnatomicalModel/AnatomicalModelResponse";
import {
  GeneralAnswer,
  isUnanswered,
  NonconformingValueKind,
  SingleValuedAnswer,
} from "../../models/answers";
import {
  CoreDataType,
  IndexedString,
  ValueAtom,
} from "../../models/core-data-types";
import { ResponseLayout } from "../../models/layouts";
import { SystemOfMeasure } from "../../models/measurements";
import {
  ComputedQuestionDefinition,
  ComputedQuestionFunction,
} from "../../models/questions";
import { QuestionnaireDefinition } from "../../store/slices/definitions";

export const HARDCODED_COMPUTATION_FUNCTIONS: Record<
  string,
  ComputedQuestionFunction
> = {
  /**
   * Define a 'refinement' relationship between an inital multi-select question
   * and its optional followup disambiguator. The result of this computation
   * will be 1) the answer to the inital question, if it has exactly one, or 2)
   * the answer to the followup.
   */
  refiner: (
    host: ComputedQuestionDefinition,
    changedId: string,
    changedAnswer: GeneralAnswer,
    allAnswers: Record<string, GeneralAnswer>,
    currentCalculationResults: Record<string, GeneralAnswer>,
    questionnaire: QuestionnaireDefinition,
    arg: { sourceQuestionId: string; followupQuestionId: string }
  ): GeneralAnswer | null => {
    const sourceAnswer =
      changedId === arg.sourceQuestionId
        ? changedAnswer
        : allAnswers[arg.sourceQuestionId];
    const sourceQuestion = questionnaire.questions[arg.sourceQuestionId];
    const followupAnswer =
      changedId === arg.followupQuestionId
        ? changedAnswer
        : allAnswers[arg.followupQuestionId];

    if (sourceAnswer && sourceAnswer?.values?.length === 1) {
      return {
        questionKey: arg.sourceQuestionId,
        isComputed: true,
        isMulti: false,
        value: sourceAnswer.values[0],
      };
    } else if (
      followupAnswer &&
      typeof followupAnswer.value?.value === "string"
    ) {
      const originalIndex = (
        sourceQuestion as ChoiceQuestionDefinition
      ).choices?.findIndex((c) => c.value === followupAnswer.value?.value);
      return {
        questionKey: arg.sourceQuestionId, // still source?
        isComputed: true,
        isMulti: false,
        value: {
          value: followupAnswer.value.value,
          choiceIndex: originalIndex,
        },
      };
    } else {
      return {
        questionKey: arg.sourceQuestionId, // still source?
        isComputed: true,
        isMulti: false,
        value: { value: null },
        nonconformingValues: [
          {
            kind: NonconformingValueKind.InactiveComputation,
            value: "(this computation did not run)",
          },
        ],
      };
    }
  },

  /**
   * Return the first answered value in a list of ids. If a list is provided as
   * the argument, that is used, otherwise it uses the computation's triggers
   * in order.
   */
  firstAnsweredChoice: (
    host: ComputedQuestionDefinition,
    changedId: string,
    changedAnswer: GeneralAnswer,
    allAnswers: Record<string, GeneralAnswer>,
    currentCalculationResults: Record<string, GeneralAnswer>,
    questionnaire: QuestionnaireDefinition,
    arg?: string[]
  ): GeneralAnswer | null => {
    const prioritizedIdList = Array.isArray(arg) ? arg : host.triggers;
    for (let id of prioritizedIdList) {
      const a = id === changedId ? changedAnswer : allAnswers[id];
      if (isUnanswered(a)) {
        continue;
      }
      const questionKey = a.questionKey ?? id;
      const q = questionnaire.questions[questionKey];
      const ncvs = Array.isArray(a.nonconformingValues)
        ? { nonconformingValues: a.nonconformingValues.map((v) => ({ ...v })) }
        : {};
      if (a.isMulti) {
        return {
          questionKey,
          isComputed: true,
          isMulti: true,
          values: a.values.map((v) => ({ ...v })),
          ...ncvs,
        };
      } else {
        return {
          questionKey,
          isComputed: true,
          isMulti: false,
          value: { ...a.value },
          ...ncvs,
        };
      }
    }
    return null;
  },

  emergency_detailedChiefComplaint: (
    host: ComputedQuestionDefinition,
    changedId: string,
    changedAnswer: GeneralAnswer,
    allAnswers: Record<string, GeneralAnswer>,
    currentCalculationResults: Record<string, GeneralAnswer>,
    questionnaire: QuestionnaireDefinition,
    arg: void
  ): GeneralAnswer | null => {
    const answersMerged = {
      ...allAnswers,
      [changedId]: changedAnswer,
      ...currentCalculationResults,
    };
    function response(
      value: string | null,
      questionKey?: string,
      otherProps?: Partial<GeneralAnswer>
    ): SingleValuedAnswer<ValueAtom> {
      return {
        ...(questionKey ? { questionKey } : {}),
        ...otherProps,
        value: { value },
        values: undefined,
        isMulti: false,
        isComputed: true,
      };
    }

    // This value could've been newly computed or already be in answers
    const singularCC = answersMerged["chief complaint"] as
      | SingleValuedAnswer<IndexedString>
      | undefined;
    if (isUnanswered(singularCC)) {
      // Returning "early" here is important -- the specific problem questions
      // may have answers because of backtracking, but we want to be unvalued
      // until a single CC is resolved. Also recall that returning null causes
      // no change to occur, so we want to deliberately return an empty answer.
      return response(null, undefined, {
        nonconformingValues: [
          {
            kind: NonconformingValueKind.InactiveComputation,
            value: "(no singular CC available)",
          },
        ],
      });
    }

    // now we go through the detailed entries
    switch (singularCC?.value.value) {
      case "Genital problem":
        const genitalAnswer = answersMerged["specific genital problem"];
        if (isUnanswered(genitalAnswer, false)) {
          return response("Genital problem", "chief complaint unrefined");
        } else {
          return response(
            genitalAnswer.value?.value as string,
            "specific genital problem"
          );
        }
      case "Urinary problem":
        const urinaryAnswer = answersMerged["specific urinary problem"];
        if (isUnanswered(urinaryAnswer, false)) {
          return response("Urinary problem", "chief complaint unrefined");
        } else {
          return response(
            urinaryAnswer.value?.value as string,
            "specific urinary problem"
          );
        }
      case "Muscles, bone, or joint pain":
        const singularROP = answersMerged["region of problem"];
        if (isUnanswered(singularROP, false)) {
          return response(
            "Muscles, bone, or joint pain",
            "chief complaint unrefined"
          );
        } else {
          if (singularROP.value?.value == NO_ANSWER_SIGNAL) {
            // TODO: we should eventually change this to an NCV of type
            // IncompleteResponse, but trying to change it quickly is a risk as
            // it seems to affect the viewPath calculations in a way I can't
            // untangle right now.
            if (Array.isArray(singularROP.value.viewPath)) {
              const lastView = capitalizeFirstLetter(
                singularROP.value.viewPath[
                  singularROP.value.viewPath.length - 1
                ]
              );
              return response(
                `${lastView} pain`,
                "region of problem unrefined"
              );
            } else {
              return response(
                "Muscles, bone, or joint pain",
                "chief complaint unrefined"
              );
            }
          }
          // handle intermediate values?
          return response(
            `${singularROP.value?.value} pain`,
            "region of problem unrefined"
          );
        }
      default:
        return response(
          singularCC?.value.value as string,
          "chief complaint unrefined"
        );
    }
  },

  emergency_bodyMassIndex: (
    host: ComputedQuestionDefinition,
    changedId: string,
    changedAnswer: GeneralAnswer,
    allAnswers: Record<string, GeneralAnswer>,
    currentCalculationResults: Record<string, GeneralAnswer>,
    questionnaire: QuestionnaireDefinition,
    decimalAccuracy: number = 1
  ): GeneralAnswer | null => {
    const height = changedId === "height" ? changedAnswer : allAnswers.height;
    const weight = changedId === "weight" ? changedAnswer : allAnswers.weight;
    if (isUnanswered(height, false) || isUnanswered(weight, false)) {
      if (changedId === "height" || changedId === "weight") {
        return {
          isComputed: true,
          isMulti: false,
          value: { value: null },
          nonconformingValues: [
            {
              kind: NonconformingValueKind.InactiveComputation,
              value: "n/a",
            },
          ],
        };
      } else {
        return null;
      }
    }

    const hMeters =
      height.system === SystemOfMeasure.Metric
        ? (height.values?.[0]?.value as number) / 100 // cm → m
        : (height.values?.[0]?.value as number) / 3.281 + // ft → m
          (height.values?.[1]?.value as number) / 39.37; // in → m
    const wKilograms =
      weight.system === SystemOfMeasure.Metric
        ? (weight.values?.[0]?.value as number) // kg
        : (weight.values?.[0]?.value as number) / 2.205; // lb → kg
    const bmi = wKilograms / (hMeters * hMeters);

    return {
      isComputed: true,
      isMulti: false,
      value: { value: Number.parseFloat(bmi.toFixed(decimalAccuracy)) },
    };
  },
};

// =========================== pre-sheets versions ===========================
//     (These will be deleted once we're happy with the in-JSON format!)
const DisambiguatedChiefComplaint: ComputedQuestionDefinition = {
  isComputed: true,
  id: "chief complaint",
  coreType: CoreDataType.SingleChoiceText,
  layout: ResponseLayout.Empty,
  isMulti: false,
  triggers: ["chief complaint unrefined", "chief complaint followup"],
  // duplicatesChoicesOf: "chief complaint unrefined",
  hardcodedFunctionName: "refiner",
  hardcodedFunctionArgument: {
    sourceQuestionId: "chief complaint unrefined",
    followupQuestionId: "chief complaint followup",
  },
  formula: [],
  // formula: [
  //   "IF_THEN",
  //   ["ANSWERED", "?chief complaint followup"],
  //   "?chief complaint followup",
  //   [["RESPONSE_COUNT", "?chief complaint unrefined"], "=", 1],
  //   ["?chief complaint unrefined", "RESPONSE_AT", 0],
  //   "NULL",
  // ],
};
const DisambiguatedRegionOfProblem: ComputedQuestionDefinition = {
  isComputed: true,
  id: "region of problem",
  coreType: CoreDataType.SingleChoiceText,
  layout: ResponseLayout.Empty,
  isMulti: false,
  triggers: ["region of problem unrefined", "region of problem followup"],
  hardcodedFunctionName: "refiner",
  hardcodedFunctionArgument: {
    sourceQuestionId: "region of problem unrefined",
    followupQuestionId: "region of problem followup",
  },
  formula: [],
};

const DetailedChiefComplaint: ComputedQuestionDefinition = {
  isComputed: true,
  id: "detailed chief complaint",
  coreType: CoreDataType.SingleChoiceText,
  layout: ResponseLayout.Empty,
  isMulti: false,
  triggers: [
    "chief complaint unrefined",
    "chief complaint followup",
    "specific genital problem",
    "specific urinary problem",
    "region of problem unrefined",
    "region of problem followup",
  ],
  hardcodedFunctionName: "emergency_detailedChiefComplaint",
  formula: [],
};

const BodyMassIndex: ComputedQuestionDefinition = {
  isComputed: true,
  id: "body mass index",
  coreType: CoreDataType.Number,
  layout: ResponseLayout.Empty,
  isMulti: false,
  triggers: ["height", "weight"],
  hardcodedFunctionName: "emergency_bodyMassIndex",
  formula: [],
};

const TotalPackYears: ComputedQuestionDefinition = {
  isComputed: true,
  id: "total pack years",
  coreType: CoreDataType.Number,
  layout: ResponseLayout.Empty,
  isMulti: false,
  triggers: [
    "tobacco packs or catridges per day",
    "years since started smoking",
  ],
  formula: [
    "?tobacco packs or catridges per day",
    "MULTIPLIED_BY",
    "?years since started smoking",
  ],
};

const gestationalAgeFormula = [
  [
    [
      "WEEKS_TO_TODAY",
      [
        "IF_THEN",
        ["ANSWERED", "?estimated delivery date"],
        "?estimated delivery date",
        ["ANSWERED", "?first day of last menstrual period"],
        ["?first day of last menstrual period", "DATE_ADD", [[280, "days"]]],
        "NULL",
      ],
    ],
    "PLUS",
    40,
  ],
  "FIXED_DIGITS",
  1,
];
