import { EntityId } from "@reduxjs/toolkit";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { dualName, Language } from "../../constants/locales";
import {
  generateQuestionnaireKind,
  ScreeningType,
} from "../../constants/screenings";
import { fn } from "../../database";
import {
  AnswerSet,
  AnswerSetSubmissionStatus,
  isUnanswered,
  MAX_RESUME_TIME_MS,
  naturalSortForSubmissionStatus,
  SpecialAnswerKeys,
  stringifyForCSV,
} from "../../models/answers";
import {
  MetricsEvent,
  MetricsEventData,
  MetricsSession,
  PageEventData,
} from "../../models/metrics";
import { UserType } from "../../models/users";
import { answerSetSlice } from "../../store/slices/answerSets";
import { QuestionnaireDefinition } from "../../store/slices/definitions";
import { metricsSlice } from "../../store/slices/metrics";
import { requestQuestionnaire } from "../../store/slices/questionnaire-old";
import { isObject, oneline, ordinals, safeStringify } from "../../utils";
import { isProcessing, LoadState } from "../../utils/database";
import {
  useAppDispatch,
  useAppSelector,
  useKeyboardEvents,
} from "../../utils/hooks";
import { JSObjectDump } from "../../utils/UtilComponents";
import Footer from "../Questionnaire/Partials/Footer";
import { ReportContainer } from "../Report/ReportContainer";
import { CTA } from "../UI/buttons/CTA";
import { SmallModalDialog } from "../UI/dialogs/SmallModalDialog";
import { IS_QA } from "../../constants/app";
import { ValueAtom } from "../../models/core-data-types";
import Note from "../Questionnaire/Partials/Note";

const SORT_CSV_ROWS: null | "alphabetical" | "definition" = "definition";

const DEFAULT_SORT: [
  (as: AnswerSet) => any,
  number,
  boolean | undefined,
  boolean | undefined
] = [(a: AnswerSet) => a.submitted, 3, true, false];

function isResumeable(as: AnswerSet): boolean {
  return (
    as.submissionType !== AnswerSetSubmissionStatus.FlowCompleted &&
    Date.now() - as.submitted < MAX_RESUME_TIME_MS
  );
}

function sorterForSubmissionType(as: AnswerSet) {
  if (as.submissionType === AnswerSetSubmissionStatus.FlowCompleted)
    return -1000;
  const statusIndex = naturalSortForSubmissionStatus(as.submissionType);
  return (isResumeable(as) ? 0 : 100) + statusIndex;
}

enum SessionStatus {
  Completed = "Completed",
  Suspended = "Suspended",
  Abandoned = "Abandoned",
}

function sessionStatus(as: AnswerSet): SessionStatus {
  if (as.submissionType === AnswerSetSubmissionStatus.FlowCompleted) {
    return SessionStatus.Completed;
  }
  return isResumeable(as) ? SessionStatus.Suspended : SessionStatus.Abandoned;
}

function summarizingData(
  answerSet: AnswerSet,
  field: "age" | "sex" | "chief complaint" | "report ID",
  forSort: boolean = true
) {
  let result: ValueAtom | string = "";
  if (field === "age") {
    if (forSort) {
      result = answerSet.answers?.["birthday"]?.value ?? {
        value: Number.MIN_SAFE_INTEGER,
      };
    } else {
      result = isUnanswered(answerSet.answers?.["birthday"], false)
        ? "--"
        : `${moment().diff(
            moment(answerSet.answers["birthday"].value!!.value as number),
            "years"
          )}`;
    }
  }
  if (field === "sex") {
    result = answerSet.answers?.["sex assigned at birth"]?.value ?? "--";
  }
  if (field === "chief complaint") {
    const detailedCC = answerSet.answers?.["detailed chief complaint"]?.value;
    const singularCC = answerSet.answers?.["chief complaint"]?.value;
    result = detailedCC ?? singularCC ?? "--";
  }
  if (field === "report ID") {
    result =
      answerSet.answers?.[SpecialAnswerKeys.GeneratedReportID]?.value ?? "--";
  }
  // something is wrong with our value atom setup
  if (isObject(result, true)) {
    if ("value" in result) {
      return result.value;
    } else {
      return safeStringify(result);
    }
  } else {
    return result;
  }
}

const DASHBOARD_PAGE_SIZE: number = 50;

export const ProviderDashboard = ({
  simulation: isSimulatedSession,
}: {
  simulation?: {
    kioskAction: () => void;
    answerSets: AnswerSet[];
  };
}) => {
  const loadedAnswerSets = useAppSelector((s) => s.answerSets.allSets);
  const tableData = useMemo(() => {
    if (isSimulatedSession) {
      return isSimulatedSession.answerSets;
    }
    const table = loadedAnswerSets.ids.map((i) => loadedAnswerSets.entities[i]);
    if (!DEFAULT_SORT[2]) {
      console.error(
        `Default sort does not support non-numerics yet. Update this code!`
      );
    }
    const f: (a: AnswerSet) => number = DEFAULT_SORT[0];
    const i = DEFAULT_SORT[3] ? 1 : -1;
    table.sort((a, b) => (f(a) - f(b)) * i);
    return table;
  }, [isSimulatedSession, loadedAnswerSets]);
  const [sortedTableData, setSortedTableData] = useState(tableData);
  useEffect(() => {
    // preserve sort would be nice
    setSortedTableData(tableData);
  }, [tableData]);
  const [page, setPage] = useState(0);
  const pageCount = Math.ceil(sortedTableData.length / DASHBOARD_PAGE_SIZE);
  const pageRangeStart = page * DASHBOARD_PAGE_SIZE;
  const pageRangeEnd = Math.min(
    (page + 1) * DASHBOARD_PAGE_SIZE,
    tableData.length
  );

  const user = useAppSelector((s) => s.auth.user);
  const dispatch = useAppDispatch();

  const hardcodedQType = generateQuestionnaireKind({
    type: ScreeningType.ED,
    locale: { language: Language.English },
  });
  // const loadedQuestionnaireIDs = useAppSelector((s) => s.definitions.questionnaires.ids);
  const loadedQuestionnaires = useAppSelector(
    (s) => s.questionnaire.questionnaires
  );
  const qTypeIsReady = loadedQuestionnaires.ids.includes(hardcodedQType);
  const [displayReport, setDisplayReport] = useState<AnswerSet | null>(null);
  const [exportedCSV, setExportedCSV] = useState<{
    raw: string;
    tabular: string[][];
    lines: number;
    generated: number;
    blobUrl: string;
  } | null>(null);

  function showReport(id: string | undefined) {
    if (!id) {
      alert(`Report has invalid ID!`);
      return;
    }
    const set = tableData.find((as) => as.id === id);
    if (!set) {
      alert(`Could not find id ${id} in table!`);
      return;
    }
    if (!qTypeIsReady) {
      dispatch(
        requestQuestionnaire({
          type: ScreeningType.ED,
          locale: { language: Language.English },
        })
      );
    }
    dispatch(
      metricsSlice.actions.recordEvent({
        type: MetricsEvent.ViewDashboardReport,
      })
    );
    setDisplayReport(set);
  }

  function csvExport() {
    if (!displayReport) {
      return alert(`No active report`);
    }

    const questions = (
      loadedQuestionnaires.entities[hardcodedQType] as (
        | QuestionnaireDefinition
        | undefined
      ) & { id: EntityId }
    )?.questions;
    if (!questions) {
      return alert(`Could not retrieve questions`);
    }

    // make our answer set "flatter" by converting multi-valued answers to be
    // separate rows in our final CSV
    const order: Record<string, number> = Object.fromEntries(
      Object.keys(questions).map((qk, i) => [qk, i])
    );
    order[SpecialAnswerKeys.GeneratedReportID] = -0.5;
    order[SpecialAnswerKeys.ConsentTimestamp] = -0.2;

    const rows: Array<[string, string, string, number]> = [];
    Object.entries((displayReport as AnswerSet).answers).forEach(
      ([key, answer]) => {
        const keyPrefix = key.includes("§")
          ? key.slice(0, key.indexOf("§"))
          : key;
        const question = questions[answer.questionKey ?? keyPrefix]; // Retrieve the question corresponding with the answer
        const subEntries = stringifyForCSV(
          key,
          answer,
          question?.reportLabel ?? "",
          question
        );
        subEntries.forEach((row) =>
          rows.push([
            ...row,
            order[answer.questionKey ?? keyPrefix] ?? Number.MAX_SAFE_INTEGER,
          ])
        );
      }
    );

    if (SORT_CSV_ROWS === "alphabetical") {
      // sort the rows by the natural string order of their keys
      rows.sort((r1, r2) => {
        return r1[0].localeCompare(r2[0]);
      });
    } else if (SORT_CSV_ROWS === "definition") {
      // attempt to sort the rows into the 'definition order' given by the JSON
      rows.sort(
        (
          r1: [string, string, string, number],
          r2: [string, string, string, number]
        ) => r1[3] - r2[3]
      );
    }

    rows.unshift([
      // include a header row
      `Answer Key`,
      `Report Label`,
      `Answer Value`,
      -1,
    ]);
    const csvOut = rows.map((row) => row.slice(0, 3).join(",")).join("\n");
    const fileBlob = new Blob([csvOut], { type: "text/csv" });
    const blobUrl = URL.createObjectURL(fileBlob);

    setExportedCSV({
      raw: csvOut,
      tabular: rows.map((r) => r.slice(0, 3) as [string, string, string]),
      lines: rows.length,
      generated: Date.now(),
      blobUrl,
    });
  }

  /**
   * This generates a visualized table that is slightly more human readable than
   * raw CSV.
   */
  const EMPTY_CELL_STRING = "~";
  const CELL_SEPARATOR = " | ";
  const LINE_SEPARATOR = "\n";
  const formattedText = exportedCSV?.tabular
    .map((row) =>
      row
        .map((value) => value.replace(/^"|"$/g, "").trim())
        .map((value) => (value === "" ? EMPTY_CELL_STRING : value))
        .join(CELL_SEPARATOR)
    )
    .join(LINE_SEPARATOR);

  const [displayMetrics, setDisplayMetrics] = useState<string | null>(null);
  const [sessionIndexInList, setSessionIndexInList] = useState<number>(-1);
  const metricsSession = useAppSelector(
    (s) => s.metrics.sessions[displayMetrics ?? ""]
  );
  function showMetrics() {
    if (displayReport === null) return;
    if (displayReport.sessionIds.length < 1) {
      alert(`Report has no available metrics session!`);
      return;
    } else if (displayReport.sessionIds.length > 1) {
      //
    }
    const id = displayReport.sessionIds[0];
    dispatch(metricsSlice.actions.loadMetricsSession(id));
    setDisplayMetrics(id);
    setSessionIndexInList(0);
  }

  function walkSessionList(backward: boolean = false) {
    if (displayReport === null) return;
    const newIndex =
      (sessionIndexInList + (backward ? -1 : 1)) %
      displayReport.sessionIds.length;
    const id = displayReport.sessionIds[newIndex];
    dispatch(metricsSlice.actions.loadMetricsSession(id));
    setDisplayMetrics(id);
    setSessionIndexInList(newIndex);
  }

  useEffect(() => {
    if (isSimulatedSession) return;
    if (loadedAnswerSets.loadState === LoadState.Unstarted) {
      if (
        user.type !== UserType.Provider &&
        user.type !== UserType.Superadmin
      ) {
        console.warn("User is wrong type for dashboard!");
      } else {
        dispatch(
          answerSetSlice.actions.loadDashboardAnswerSets(user.organizationId)
        );
      }
    }
  }, [loadedAnswerSets, user]);

  function reloadReports() {
    dispatch(
      answerSetSlice.actions.loadDashboardAnswerSets(user.organizationId)
    );
  }

  const [debug_forceAllAnswerReport, setDebug_forceAllAnswerReport] =
    useState(false);
  useKeyboardEvents([
    "F6",
    () => {
      setDebug_forceAllAnswerReport((x) => !x);
    },
  ]);

  const [sortedColumn, setSortedColumn] = useState<number>(DEFAULT_SORT[1]);
  const [sortAscending, setSortAscending] = useState<boolean>(
    !!DEFAULT_SORT[3]
  );
  function sortTable(
    accessor: (as: AnswerSet) => any,
    index: number,
    numeric = false
  ) {
    let ascending = true;
    if (index === sortedColumn) {
      ascending = !sortAscending;
      setSortAscending(ascending);
    } else {
      setSortedColumn(index);
      setSortAscending(true);
    }
    let newTable = tableData.slice();
    const inverter = ascending ? 1 : -1;
    if (numeric) {
      newTable.sort((a, b) => (accessor(a) - accessor(b)) * inverter);
    } else {
      newTable.sort(
        (a, b) => `${accessor(a)}`.localeCompare(`${accessor(b)}`) * inverter
      );
    }
    setSortedTableData(newTable);
    setPage(0);
  }

  function sortIcon(index: number) {
    return null;
    if (index === sortedColumn) {
      if (sortAscending) {
        return (
          <span
            className="sort-icon"
            style={{ marginRight: "0.5em", textDecoration: "none" }}
          >
            ⬇
          </span>
        );
      } else {
        return (
          <span
            className="sort-icon"
            style={{ marginRight: "0.5em", textDecoration: "none" }}
          >
            ⬆
          </span>
        );
      }
    } else {
      return " ";
    }
  }

  function sortStyle(index: number): React.CSSProperties {
    if (index === sortedColumn) {
      return {
        textDecoration: "dotted underline 1px gray",
      };
    } else {
      return {};
    }
  }

  async function copyToClipboard(text: string) {
    await navigator.clipboard.writeText(text);
    alert(`Copied "${text}" to your clipboard!`);
  }

  const [showKioskDialog, setShowKioskDialog] = useState<boolean | string>(
    false
  );
  const recordForResume =
    typeof showKioskDialog === "string"
      ? tableData.find((as) => as.id === showKioskDialog)
      : undefined;
  const [pin, setPin] = useState<string>("");
  const pinIsValid: boolean = /^\d{4,6}$/g.test(pin);
  const [kioskRequestPending, setKioskRequestPending] =
    useState<boolean>(false);

  function startKioskFlow(e: MouseEvent) {
    const forceOldMethod = false; // e.shiftKey;
    const sessionToResume =
      typeof showKioskDialog === "string" ? showKioskDialog : undefined;

    if (forceOldMethod) {
      throw new Error(oneline`
        This branch is disabled now that token-based kiosk flow is moving into
        production.`);
      /*
       const confirmed = true;
      if (confirmed) {
        if (isSimulatedSession) {
          isSimulatedSession.kioskAction();
        } else {
          if (
            user.organization?.kioskUsers &&
            user.organization.kioskUsers.length > 0
          ) {
            // In the future when we hve multiple kiosks defined per organization,
            // we would need to have selected one using a UI prior to this call.
            const kioskUserId = user.organization.kioskUsers[0];
            dispatch(
              authSlice.actions.startKioskMode({
                kioskUserId,
                organization: user.organization,
              })
            );
            dispatch(metricsSlice.actions.startNewMetricsSession());
            if (user.type === UserType.Superadmin) {
              dispatch(
                simulatorSlice.actions.changeSimulatorSettings({
                  showExpandedLanguages: true,
                })
              );
            }
          } else {
            console.error(`Organization has no available kiosk users!`);
          }
        }
      }
      */
    } else {
      if (pin.length > 0 && !pinIsValid) {
        return;
      }
      const getKioskToken = fn.httpsCallable("requestKioskMode");
      setKioskRequestPending(true);
      getKioskToken({ pin }).then(
        ({ data }) => {
          console.warn({ data });
          window.sessionStorage.setItem("tokenSessionId", data.sessionId);
          window.sessionStorage.setItem("authToken", data.kioskToken);
          if (sessionToResume) {
            window.sessionStorage.setItem("loadAnswerSet", sessionToResume);
          }
          setKioskRequestPending(false);
          setShowKioskDialog(false);
          setTimeout(() => {
            // we are about to reload, this debugger statement is to catch
            // anything we want to look at before the page is cleared
            window.location.reload();
          }, 50);
        },
        (err) => {
          console.error({ err });
          setKioskRequestPending(false);
        }
      );
    }
  }

  function metricsTimeSince(session: MetricsSession, e: MetricsEventData) {
    const ms = e.timestamp - session.initialTimestamp;
    return `${Math.floor(ms / 100) / 10}s`;
  }

  function deleteRecord() {
    if (!displayReport || !displayReport.id) {
      alert("No ID available for removal.");
      return null;
    }
    const confirmed = window.confirm(
      `Are you sure you want to delete the Firebase record ${
        displayReport.id
      } (saved ${moment(displayReport.submitted).fromNow()}; ${
        Object.keys(displayReport.answers).length
      } answers)?\n\nTHIS CANNOT BE UNDONE`
    );
    if (confirmed) {
      dispatch(answerSetSlice.actions.deleteAnswerSet(displayReport.id));
      setTimeout(() => setDisplayReport(null), 200);
    }
  }
  function resumeSession(id: string) {
    setShowKioskDialog(id ?? true);
  }
  function resumeForQA(event: MouseEvent, id: string) {
    if (IS_QA && event.shiftKey) {
      resumeSession(id);
    }
  }
  return (
    <>
      <header
        style={{
          backgroundColor: "hsl(0, 0%, 95%)",
          borderBottom: "1px solid #cccccc",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className="header-container"
          style={{
            padding: "10px 20px",
            maxWidth: "1080px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "0 auto",
          }}
        >
          {/* <div
    className="header-left"
    style={{ display: "flex", alignItems: "center", gap: "20px" }}
  > */}
          <img
            src="/images/LTSD_green_logo.png"
            alt="Company Logo"
            className="logo"
            style={{ height: "26px" }}
          />
          <h2
            className="title"
            style={{
              margin: 0,
              fontSize: "20px",
              fontWeight: "600",
              color: "var(--header-font)",
            }}
          >
            Valleywise Health Emergency Department
          </h2>
          {/* </div>
  <div className="header-right"> */}
          <CTA
            light={false}
            label="Launch App"
            className="header-button"
            style={{
              // width: "100%",
              minHeight: "auto",
              width: "auto",
              padding: "10px 20px",
              fontSize: "1.25rem",
            }}
            onClick={() => setShowKioskDialog(true)}
            disabled={!user.organization?.allowsKioskMode}
          />
        </div>
        {/* </div> */}
      </header>

      <div
        className="centered-flex-container"
        style={{
          // minHeight: "100vh"
          minHeight: "calc(100vh - 124px)",
        }}
      >
        {displayReport ? (
          qTypeIsReady ? (
            displayMetrics ? (
              <div
                className="raised-box"
                style={{ maxWidth: "1000px", margin: "3em auto" }}
              >
                <CTA
                  style={{ width: 400, backgroundColor: "var(--salmon-red)" }}
                  label="Back to Report"
                  onClick={() => setDisplayMetrics(null)}
                />
                <div style={{ float: "right" }}>
                  <button
                    className="btn"
                    onClick={() => walkSessionList(true)}
                    disabled={sessionIndexInList < 1}
                  >
                    Prev
                  </button>
                  <strong style={{ margin: "1em" }}>
                    {sessionIndexInList + 1} of{" "}
                    {displayReport.sessionIds.length}
                  </strong>
                  <button
                    className="btn"
                    onClick={() => walkSessionList(false)}
                    disabled={
                      sessionIndexInList >= displayReport.sessionIds.length - 1
                    }
                  >
                    Next
                  </button>
                </div>
                {metricsSession ? (
                  <div>
                    <h3>{metricsSession.sessionId}</h3>
                    {metricsSession.sessionId.startsWith("INIT") ? (
                      <em
                        style={{
                          color: "red",
                          display: "block",
                          marginTop: "-1em",
                        }}
                      >
                        (This is likely a broken session link due to the prefix{" "}
                        <kbd>INIT</kbd>.)
                      </em>
                    ) : null}
                    <h5>
                      {moment(metricsSession.initialTimestamp).format(
                        "YYYY-MM-DD HH:mm:ssa"
                      )}
                    </h5>
                    <ol>
                      {metricsSession.events.map((e) => (
                        <li>
                          ({metricsTimeSince(metricsSession, e)})
                          <strong>{` ${e.type} `}</strong>
                          {/* NAVIGATIONAL DATA */}
                          {Number.isSafeInteger(
                            (e as PageEventData).fromPage
                          ) ? (
                            <>
                              {(e as PageEventData).fromPage}→
                              {(e as PageEventData).toPage}
                            </>
                          ) : null}
                          {Object.keys(e).length > 2 ? (
                            <JSObjectDump
                              obj={e}
                              omit={["type", "timestamp"]}
                              style={{ fontSize: "80%", opacity: 0.8 }}
                            />
                          ) : (
                            <span style={{ float: "right", opacity: 0.25 }}>
                              no data
                            </span>
                          )}
                        </li>
                      ))}
                      {metricsSession.events.length === 0 ? (
                        <li>
                          <h2>No events were found!</h2>
                          <p>
                            The metrics session could not save after init, or
                            they could not be loaded in the first place.
                          </p>
                        </li>
                      ) : null}
                    </ol>
                  </div>
                ) : (
                  <strong>Loading metrics...</strong>
                )}
              </div>
            ) : exportedCSV ? (
              <div
                className="raised-box"
                style={{ maxWidth: "1000px", margin: "3em auto" }}
              >
                <CTA
                  style={{ width: 400, backgroundColor: "var(--blush-pink)" }}
                  label="Back to Report"
                  onClick={() => setExportedCSV(null)}
                />
                <a
                  className={`call-to-action`}
                  style={{ width: 400, float: "right" }}
                  href={exportedCSV.blobUrl}
                  download={`${moment().format("YYYY-MM-DD")} - LiteraSeed #${
                    displayReport?.humanReadableId?.id
                  } (${displayReport.language.toUpperCase()}).csv`}
                >
                  Download
                </a>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    width: "100%",
                    gap: "0.5em",
                  }}
                >
                  <div
                    style={{
                      marginTop: "1em",
                      fontSize: "0.9em",
                      color: "#555",
                    }}
                  >
                    Exported {exportedCSV.lines} rows ({exportedCSV.raw.length}{" "}
                    chars). Raw content:
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <textarea
                      rows={Math.min(25, exportedCSV.lines)} // Caps at 10 rows for better display
                      style={{
                        width: "100%",
                        maxWidth: "1080px",
                        height: "unset",
                        fontFamily: "monospace",
                        fontSize: "0.9em",
                        whiteSpace: "pre",
                        overflowX: "auto",
                        background: "#f8f9fa",
                        border: "1px solid #ddd",
                        padding: "0.75em",
                        borderRadius: "6px",
                        resize: "vertical",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
                      }}
                      value={formattedText}
                      readOnly
                    >
                      {/* {exportedCSV.raw} */}
                    </textarea>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="raised-box"
                style={{ maxWidth: "1000px", margin: "3em auto" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <CTA
                    style={{
                      width: 400,
                      fontSize: "1em",
                      backgroundColor: "var(--fresh-sprout)",
                    }}
                    label="Back to Dashboard"
                    onClick={() => setDisplayReport(null)}
                    title={displayReport?.id ?? "no id"}
                  />
                  {user.type === UserType.Superadmin ? (
                    <>
                      <CTA
                        style={{
                          width: 400,
                          fontSize: "1em",
                          backgroundColor: "var(--peach-cream)",
                        }}
                        label="View Metrics"
                        onClick={() => showMetrics()}
                      />
                      <CTA
                        style={{
                          width: 400,
                          fontSize: "1em",
                          backgroundColor: "var(--blush-pink)",
                        }}
                        label="Delete"
                        onClick={() => deleteRecord()}
                      />
                      {/* <CTA
                        style={{ width: 400, backgroundColor: "lightgray" }}
                        label="Resume Session"
                        onClick={() => resumeSession()}
                      /> */}
                      <CTA
                        style={{
                          width: 400,
                          fontSize: "1em",
                          backgroundColor: "var(--sky-mist)",
                        }}
                        label="Export CSV"
                        onClick={() => csvExport()}
                      />
                    </>
                  ) : null}
                </div>
                <ReportContainer
                  user={{ name: "ED Kiosk" }}
                  questionnaireDefinition={
                    loadedQuestionnaires.entities[hardcodedQType]
                  }
                  answers={displayReport.answers}
                  submissionStatus={displayReport.submissionType}
                  language={displayReport.language}
                  acceptAnswer={null}
                  forceAllAnswerReport={debug_forceAllAnswerReport}
                />
              </div>
            )
          ) : (
            <strong>Loading structure...</strong>
          )
        ) : (
          <div
            className="raised-box"
            style={{
              maxWidth: "1000px",
              margin: "3em auto",
              position: "relative",
            }}
          >
            <h2 className="text-centered">
              Patient Reports
              <small
                style={{
                  marginLeft: "1em",
                  opacity: 0.75,
                  verticalAlign: "center",
                }}
              >
                {pageRangeStart + 1}-{pageRangeEnd} of {tableData.length}
              </small>
            </h2>
            <button
              className="btn"
              onClick={reloadReports}
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                margin: 0,
                padding: "0.5em 1em",
                fontSize: "1.25em",
              }}
              title={`Current reports were loaded ${moment(
                loadedAnswerSets.loadTime
              ).format("h:mma")}`}
            >
              {loadedAnswerSets.loadState === LoadState.Complete ? (
                `${String.fromCharCode(8634)}`
              ) : (
                <div className="loader-dots-fade" />
              )}
            </button>
            <div className="overflow-x">
              <table className="dashboard-table">
                <thead>
                  <tr>
                    <th
                      style={{
                        fontWeight: "500",
                        paddingBottom: "12px",
                        ...sortStyle(2),
                      }}
                      onClick={() =>
                        sortTable(
                          (a) => summarizingData(a, "chief complaint"),
                          2
                        )
                      }
                    >
                      {sortIcon(2)}
                      Chief Complaint
                    </th>
                    <th
                      style={{
                        fontWeight: "500",
                        paddingBottom: "12px",
                        ...sortStyle(0),
                      }}
                      onClick={() =>
                        sortTable((a) => summarizingData(a, "age"), 0, true)
                      }
                    >
                      {sortIcon(0)}
                      Age
                    </th>
                    <th
                      style={{
                        fontWeight: "500",
                        paddingBottom: "12px",
                        ...sortStyle(1),
                      }}
                      onClick={() =>
                        sortTable((a) => summarizingData(a, "sex"), 1)
                      }
                    >
                      {sortIcon(1)}
                      Sex
                    </th>
                    <th
                      style={{
                        fontWeight: "500",
                        paddingBottom: "12px",
                        ...sortStyle(4),
                      }}
                      onClick={() => sortTable((a) => a.language, 4)}
                    >
                      {sortIcon(4)}
                      Language
                    </th>
                    <th
                      style={{
                        fontWeight: "500",
                        paddingBottom: "12px",
                        ...sortStyle(3),
                      }}
                      colSpan={2}
                      onClick={() => sortTable((a) => a.submitted, 3, true)}
                    >
                      {sortIcon(3)}
                      Time Started
                    </th>
                    <th
                      style={{
                        fontWeight: "500",
                        paddingBottom: "12px",
                        ...sortStyle(5),
                      }}
                      onClick={() =>
                        sortTable((a) => summarizingData(a, "report ID"), 5)
                      }
                    >
                      {sortIcon(5)}
                      Report ID
                    </th>
                    <th
                      style={{
                        fontWeight: "500",
                        paddingBottom: "12px",
                        ...sortStyle(6),
                      }}
                      onClick={() =>
                        sortTable(sorterForSubmissionType, 6, true)
                      }
                    >
                      {sortIcon(6)}
                      Status
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {sortedTableData.length === 0 ? (
                    <tr>
                      <th
                        colSpan={8}
                        style={{ textAlign: "center", padding: "1.5em" }}
                      >
                        {isProcessing(loadedAnswerSets)
                          ? "Loading reports"
                          : "No reports available"}
                      </th>
                    </tr>
                  ) : null}
                  {sortedTableData
                    .slice(pageRangeStart, pageRangeEnd)
                    .map((row) => (
                      <tr
                        key={row.id ?? row.submitted}
                        onDoubleClick={() => showReport(row.id)}
                        className={sessionStatus(row).toLocaleLowerCase()}
                      >
                        <td
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            maxWidth: "190px",
                          }}
                        >
                          {summarizingData(row, "chief complaint", false)}
                        </td>
                        <td
                          className="text-center"
                          style={{ color: "var(--dark-mint-green)" }}
                        >
                          {summarizingData(row, "age", false)}
                        </td>
                        <td style={{ color: "var(--dark-mint-green)" }}>
                          {summarizingData(row, "sex", false)}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            color: "var(--dark-mint-green)",
                          }}
                        >
                          {dualName(row.language ?? Language.English)}
                        </td>
                        <td style={{ color: "var(--dark-mint-green)" }}>
                          {moment(row.submitted).format("MMM D")}
                        </td>
                        <td style={{ color: "var(--dark-mint-green)" }}>
                          {moment(row.submitted).format("h:mma")}
                        </td>

                        {row.humanReadableId ? (
                          <th
                            className="monospace"
                            onClick={
                              row.humanReadableId
                                ? () =>
                                    copyToClipboard(row.humanReadableId!!.id)
                                : undefined
                            }
                          >
                            {row.humanReadableId?.id}
                          </th>
                        ) : (
                          <th className="monospace">
                            {summarizingData(row, "report ID", false)}
                          </th>
                        )}
                        <td
                          style={{ fontSize: "0.7em", textAlign: "center" }}
                          title={row.submissionType}
                          onClick={(e) => resumeForQA(e, row.id ?? "")}
                        >
                          {row.submissionType ===
                          AnswerSetSubmissionStatus.FlowCompleted ? (
                            <span>Completed</span>
                          ) : isResumeable(row) ? (
                            <button
                              className="btn btn-inline"
                              style={{ border: "0px" }}
                              onClick={() => resumeSession(row.id ?? "")}
                            >
                              Suspended ▶
                            </button>
                          ) : (
                            <span>Abandoned</span>
                          )}
                        </td>
                        <td>
                          <button
                            className=""
                            onClick={() => showReport(row.id)}
                            style={{
                              color: "var(--dark-mint-green)",
                              fontWeight: "600",
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={8} style={{ textAlign: "center" }}>
                      <button onClick={() => setPage(Math.max(page - 1, 0))}>
                        Prev
                      </button>
                      {ordinals(pageCount).map((i) => (
                        <button
                          key={`page-${i + 1}-link`}
                          style={{
                            backgroundColor:
                              page === i ? "var(--evergreen)" : "",
                            width: 30,
                            height: 30,
                            borderRadius: "50%",
                          }}
                          disabled={page === i}
                          onClick={() => setPage(i)}
                        >
                          {i + 1}
                        </button>
                      ))}
                      <button
                        onClick={() =>
                          setPage(Math.min(page + 1, pageCount - 1))
                        }
                      >
                        Next
                      </button>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}

        {showKioskDialog ? (
          <SmallModalDialog quit={() => setShowKioskDialog(false)}>
            <h2 title={`${showKioskDialog}`}>
              {typeof showKioskDialog === "string"
                ? "Resume Session in Kiosk"
                : "Start Kiosk Mode"}
            </h2>
            {!!recordForResume && !isResumeable(recordForResume) ? (
              <Note
                note={[
                  {
                    text: "This record would not normally be resumable, but holding the ",
                    highlight: "shift",
                  },
                  { text: "key in a QA environment overrode the restriction." },
                ]}
              />
            ) : null}
            <p>
              You are about to enable Kiosk Mode. This mode limits the app to
              collecting patient data. After exiting Kiosk Mode, you must log
              back in or you can optionally set a PIN for easier access back to
              this page.
            </p>
            {/* <em style={{ color: "darkred" }}>
              (QA: hold shift to skip token and default to old access mode)
            </em> */}
            <input
              type="text"
              inputMode="numeric"
              onChange={(e) => setPin(e.target.value)}
              pattern="\d{4,6}"
              placeholder="(Optional) Create PIN"
              onKeyUp={(e) => (e.key === "Enter" ? startKioskFlow(e) : null)}
            />
            <div
              style={{
                color: pinIsValid || pin === "" ? "unset" : "red",
                opacity: pin === "" ? 0.4 : 0.8,
                marginTop: "8px",
              }}
            >
              (PIN must be a 4 to 6 digit code.)
            </div>
            <hr />
            {kioskRequestPending ? (
              <CTA disabled={true}>
                <div className="loader-dots-fade" />
              </CTA>
            ) : (
              <CTA
                style={{ width: "100%" }}
                disabled={pin.length > 0 && !pinIsValid}
                onClick={startKioskFlow}
              >
                Continue {pin.length > 0 ? "with PIN" : "without PIN"}
              </CTA>
            )}
          </SmallModalDialog>
        ) : null}
      </div>
      <Footer />
    </>
  );
};
